import _ from "lodash";
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { readToken } from "./storage";
import { message } from "antd";
import { errorResponseType } from "../types/error.response";

export const BASE_URL: string =
  // process.env.NODE_ENV === "development"
  process.env.NODE_ENV === "development"
    ? "https://api.listdekhoo.com/api"
    : "https://api.listdekhoo.com/api";
export const brand_ext: string = "/brand";
export const product_ext: string = "/product";
export const category_ext: string = "/category";
export const auth_ext: string = "/auth";
export const user_ext: string = "/user";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    Accept: "*",
  },
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  } as unknown as AxiosHeaders;
  return config;
});

axiosInstance.interceptors.response.use(
  (res: AxiosResponse): any => {
    if (res.data && res.status === 200) {
      if (res.config.url?.includes("signup")) {
        message.success("Account created successfully");
      }
      if (res.config.url?.includes("login")) {
        message.success("user login successfully");
      }
      return res;
    }
  },
  (error: AxiosError) => {
    if (error?.response?.data) {
      const response: any = error.response.data;
      if(response?.error){
        message.error('something wents wrong');
      }
      if (response?.statusCode === 500) {
        message.error(response.message);
      }
      const res: any = error?.response?.data as errorResponseType;
      const errorMessage = res?.message as any;
      if (_.isArray(errorMessage)) {
        for (let i = 0; i <= errorMessage?.length; i++) {
          message.error(errorMessage[i]);
        }
      } else if (res?.error && res?.errorResponse?.message?.length>0) {
        message.error(res?.errorResponse?.message);
      }
    }
  }
);

export default axiosInstance;
